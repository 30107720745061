/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { WpMediaItem, Query } from "../../gatsby-types";

import ExperienceSeparator from "../components/ExperienceSeparator";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import LocationItem from "../components/LocationItem";
import SeasonsSeparator from "../components/SeasonsSeparator";
import SectionGrey from "../components/SectionGrey";
import TodoTogo, { DistanceTable, FishingTable, RestaurantTable, LinksTable } from "../components/TodoTogo";

import { media, Breakpoint, themeColor } from "../theme";

type PropsType = {
  data: Query;
};

type LocationSection = {
  image: WpMediaItem;
  description: string;
  isReversed?: boolean;
  isShifted?: boolean;
};

type acfLocationProps = {
  heroHeader: string;
  heroSubheader: string;
  sections: LocationSection[];
  sectionsEnding: string;
  sectionsSeparator: string;
  townsAndCities: DistanceTable;
  parksTrailsAndBeaches: DistanceTable;
  westCoastActivities: DistanceTable;
  sportFishing: FishingTable;
  fineDining: RestaurantTable;
  westCoastArt: DistanceTable;
  churches: DistanceTable;
  moreLinks: LinksTable;
};

const Separator = styled.div`
  display: block;
  height: 0;
  margin-bottom: 40px;

  ${media(Breakpoint.TabletPlus)} {
    display: block;
    height: 40px;
    width: 100%;
    background: ${themeColor("grey100")};
    margin-bottom: 0;
  }
`;

const Location: React.VFC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfLocation) {
    return null;
  }

  const { heroHeader, heroSubheader, sections, sectionsEnding, sectionsSeparator, ...tables } = props.data.wpPage
    .acfLocation as acfLocationProps;

  return (
    <Layout>
      <Seo post={props.data.wpPage} />
      <Hero page="location" header={heroHeader} paragraph={heroSubheader} />
      <Separator />
      {sections.map((section, idx) => (
        <React.Fragment key={idx}>
          <LocationItem
            image={section.image.localFile}
            text={section.description}
            isReversed={section.isReversed}
            isShifted={section.isShifted}
          />
          {idx === 0 && <SeasonsSeparator text={sectionsSeparator} />}
        </React.Fragment>
      ))}
      <ExperienceSeparator text={sectionsEnding} />
      <SectionGrey>
        <TodoTogo {...tables} />
      </SectionGrey>
    </Layout>
  );
};

export const pageQuery = graphql`
  query LocationPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfLocation {
        heroHeader
        heroSubheader
        sections {
          description
          isReversed
          isShifted
          image {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1420, height: 960)
              }
            }
          }
        }
        sectionsSeparator
        sectionsEnding
        townsAndCities {
          name
          link
          drive
          walk
          bike
        }
        parksTrailsAndBeaches {
          name
          link
          drive
          walk
          bike
        }
        westCoastActivities {
          name
          link
          drive
          walk
          bike
        }
        sportFishing {
          name
          link
          where
        }
        fineDining {
          name
          link
          drive
          restaurant
        }
        westCoastArt {
          name
          link
          drive
          walk
          bike
        }
        churches {
          name
          link
          drive
          walk
          bike
        }
        moreLinks {
          name
          link
        }
      }
    }
  }
`;

export default Location;

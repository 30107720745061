import styled from "@emotion/styled";
import React from "react";

import { media, Breakpoint, themeColor } from "../../theme";

const Icon = styled.img`
  position: absolute;
  left: 50%;
  margin-left: -87px;
  top: 50%;
  margin-top: -128px;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("white")};
  margin: 0 auto;
  max-width: 1130px;

  span {
    display: none;

    ${media(Breakpoint.Tablet)} {
      display: inline;
    }
  }
`;

interface ExperienceSeparatorProps {
  className?: string;
  text: string;
}

const ExperienceSeparator: React.FC<ExperienceSeparatorProps> = ({ className, text }) => {
  return (
    <div className={className}>
      <Icon alt="logo" src={`/tree-decor-light.svg`} />
      <Title dangerouslySetInnerHTML={{ __html: `&quot;${text}&quot;` }} />
    </div>
  );
};

export default styled(ExperienceSeparator)`
  position: relative;
  background: ${themeColor("grey700")};
  padding: 40px 20px;
  min-height: 336px;

  ${media(Breakpoint.Tablet)} {
    padding: 120px 40px;
    min-height: none;
  }
`;

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { File, Maybe } from "../../../gatsby-types";

import { media, Breakpoint, themeColor } from "../../theme";

const ImageContainer = styled.div<{ isShifted: boolean; isReversed: boolean }>`
  position: relative;
  width: 100%;
  max-width: 335px;
  height: 210px;
  margin: 0 auto;
  align-self: ${({ isReversed }) => (isReversed ? 2 : 0)};

  ${media(Breakpoint.TabletPlus)} {
    width: 100%;
    max-width: ${({ isShifted }) => (isShifted ? "595px" : "710px")};
    height: 480px;
    margin: 0;
    justify-self: ${({ isReversed }) => (isReversed ? "flex-end" : "flex-start")};
    order: ${({ isReversed }) => (isReversed ? 2 : 0)};
  }

  > div {
    &:first-of-type {
      height: 100%;
    }
  }
`;

const TextContainer = styled.div`
  display: grid;
  align-items: center;
  max-width: 335px;
  margin: 0 auto;

  ${media(Breakpoint.TabletPlus)} {
    max-width: none;
    margin: 0;
    padding: 40px;
  }

  ${media(Breakpoint.Desktop)} {
    padding: 0;
  }

  h3 {
    font-family: ${({ theme }) => theme.font.josefinSans};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-align: center;

    ${media(Breakpoint.TabletPlus)} {
      text-align: left;
      font-size: 22px;
      line-height: 36px;
    }
  }

  p {
    font-size: 14px;
    line-height: 26px;
    color: ${themeColor("black")};

    margin: 0 0 20px;

    ${media(Breakpoint.TabletPlus)} {
      font-size: 18px;
      line-height: 34px;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

function calculateDesktopGridTemplate(isReversed: boolean, isShifted: boolean): string {
  let textWidth = 0;
  let imgWidth = 0;
  if (isShifted) {
    textWidth = 555;
    imgWidth = 595;
  } else {
    textWidth = 440;
    imgWidth = 710;
  }

  if (isReversed) {
    return `${textWidth}px minmax(min-content, ${imgWidth}px)`;
  }

  return `minmax(min-content, ${imgWidth}px) ${textWidth}px`;
}

const alignmentCss = (isReversed: boolean, isShifted: boolean) => css`
  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: 1fr 1fr;
  }

  ${media(Breakpoint.Desktop)} {
    grid-template-columns: ${calculateDesktopGridTemplate(isReversed, isShifted)};
  }
`;

export interface LocationItemProps {
  className?: string;
  image?: Maybe<File>;
  text: string;
  isReversed?: boolean;
  isShifted?: boolean;
}

const LocationItem: React.FC<LocationItemProps> = ({
  className,
  image,
  text,
  isReversed = false,
  isShifted = false,
}) => {
  return (
    <div className={className} css={alignmentCss(isReversed, isShifted)}>
      <ImageContainer isReversed={isReversed} isShifted={isShifted}>
        {image && <GatsbyImage alt="Location" image={image.childImageSharp?.gatsbyImageData} />}
      </ImageContainer>
      <TextContainer>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </TextContainer>
    </div>
  );
};

export default styled(LocationItem)`
  display: grid;
  padding: 0 40px 40px;
  grid-gap: 20px;

  ${media(Breakpoint.TabletPlus)} {
    padding: 0;
    grid-gap: 0;
  }

  ${media(Breakpoint.Desktop)} {
    grid-gap: 135px;
    justify-content: ${({ isReversed }) => (isReversed ? "flex-end" : "flex-start")};
  }
`;

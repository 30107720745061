import styled from "@emotion/styled";
import React from "react";

import { DistanceTable, FishingTable, LinksTable, RestaurantTable } from "../TodoTogo";

import { media, Breakpoint, themeColor } from "../../theme";

interface TableElementProps {
  className?: string;
  data: DistanceTable;
  title: string;
  id: string;
  type: "distance" | "fishing" | "restaurant" | "links";
}

const TableElement: React.FC<TableElementProps> = ({ className, id, data, title, type }) => {
  let header: string[] = [];
  const tableItems: JSX.Element[][] = [[]];
  if (type === "distance") {
    header = [title, "drive", "walk", "bike"];
    (data as DistanceTable).forEach((item, idx) => {
      let itemLabel: JSX.Element;
      if (item.link) {
        itemLabel = (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
        );
      } else {
        itemLabel = <span>{item.name}</span>;
      }
      tableItems.push([
        itemLabel,
        <span key={`drive-${idx}`}>{item.drive || "-"}</span>,
        <span key={`walk-${idx}`}>{item.walk || "-"}</span>,
        <span key={`bike-${idx}`}>{item.bike || "-"}</span>,
      ]);
    });
  } else if (type === "fishing") {
    header = [title, "where"];
    (data as FishingTable).forEach((item, idx) => {
      let itemLabel: JSX.Element;
      if (item.link) {
        itemLabel = (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
        );
      } else {
        itemLabel = <span>{item.name}</span>;
      }
      tableItems.push([itemLabel, <span key={`where-${idx}`}>{item.where}</span>]);
    });
  } else if (type === "restaurant") {
    header = [title, "drive", "restaurant"];
    (data as RestaurantTable).forEach((item, idx) => {
      let itemLabel: JSX.Element;
      if (item.link) {
        itemLabel = (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
        );
      } else {
        itemLabel = <span>{item.name}</span>;
      }
      tableItems.push([
        itemLabel,
        <span key={`drive-${idx}`}>{item.drive}</span>,
        <span key={`restaurant-${idx}`}>{item.restaurant}</span>,
      ]);
    });
  } else if (type === "links") {
    header = [title];
    (data as LinksTable).forEach((item) => {
      let itemLabel: JSX.Element;
      if (item.link) {
        itemLabel = (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
        );
      } else {
        itemLabel = <span>{item.name}</span>;
      }
      tableItems.push([itemLabel]);
    });
  }
  return (
    <table className={className}>
      <thead>
        <tr>
          {header.map((headerItem, headerIdx) => (
            <th key={`${id}-th-${headerIdx}`}>{headerItem}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableItems.map((rowItem, rowIdx) => (
          <tr key={`${id}-tr-${rowIdx}`}>
            {rowItem.map((tdItem, tdIdx) => (
              <td key={`${id}-td-${tdIdx}`}>{tdItem}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default styled(TableElement)`
  position: relative;
  font-family: ${({ theme }) => theme.font.josefinSans};
  color: ${themeColor("grey700")};
  font-weight: 700;
  text-transform: uppercase;

  thead {
    tr {
      th {
        text-align: left;
        padding: 0px 10px 20px;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2em;

        ${media(Breakpoint.Tablet)} {
          font-size: 14px;
          line-height: 18px;
          padding-bottom: 30px;
        }

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0px 10px 10px;

        ${media(Breakpoint.Tablet)} {
          padding-bottom: 15px;
        }

        &:first-of-type {
          width: 140px;
          padding-left: 0;
          letter-spacing: 0.2em;

          a {
            color: ${themeColor("grey700")};
            text-decoration: underline;
          }

          ${media(Breakpoint.Tablet)} {
            width: 200px;
          }

          &:only-child {
            width: 100%;
          }
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      &:last-of-type {
        td {
          padding-bottom: 0;
        }
      }

      td {
        font-size: 8px;
        line-height: 12px;

        ${media(Breakpoint.Tablet)} {
          font-size: 10px;
          line-height: 14px;
        }

        &:not(:first-of-type) {
          text-transform: none;
        }
      }
    }
  }
`;

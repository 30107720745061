import styled from "@emotion/styled";
import React from "react";

import TableElement from "../TableElement";

import { media, Breakpoint, themeColor } from "../../theme";

type DistanceTableItem = {
  name: string;
  link?: string;
  drive?: string;
  walk?: string;
  bike?: string;
};

export type DistanceTable = DistanceTableItem[];

type FishingTableItem = {
  name: string;
  link?: string;
  where: string;
};

export type FishingTable = FishingTableItem[];

type RestaurantTableItem = {
  name: string;
  link?: string;
  drive: string;
  restaurant: string;
};

export type RestaurantTable = RestaurantTableItem[];

type LinksTableItem = {
  name: string;
  link?: string;
};

export type LinksTable = LinksTableItem[];

const Icon = styled.img`
  display: block;
  margin: 0 auto 40px;
  max-width: 661px;
  width: 100%;
  height: auto;

  ${media(Breakpoint.Tablet)} {
    margin: 0 auto 100px;
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("grey700")};
  margin: 0 auto;
  max-width: 340px;

  ${media(Breakpoint.Tablet)} {
    max-width: 555px;
    font-size: 46px;
    line-height: 56px;
    text-align: left;
  }

  ${media(Breakpoint.TabletPlus)} {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;

  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: 1fr 555px;
  }
`;

const Tables = styled.div`
  display: grid;
  width: 100%;
  max-width: 555px;
  justify-self: center;
`;

interface TodoTogoProps {
  className?: string;
  townsAndCities: DistanceTable;
  parksTrailsAndBeaches: DistanceTable;
  westCoastActivities: DistanceTable;
  sportFishing: FishingTable;
  fineDining: RestaurantTable;
  westCoastArt: DistanceTable;
  churches: DistanceTable;
  moreLinks: LinksTable;
}

const TodoTogo: React.FC<TodoTogoProps> = ({
  className,
  townsAndCities,
  parksTrailsAndBeaches,
  westCoastActivities,
  sportFishing,
  fineDining,
  westCoastArt,
  churches,
  moreLinks,
}) => {
  return (
    <div className={className}>
      <Icon alt="logo" src={`/map.svg`} width={661} height={387} />
      <Grid>
        <Title>Things to do, Places to go</Title>
        <Tables>
          <TableElement data={townsAndCities} id="towns_cities" title="Towns & Cities" type="distance" />
          <TableElement
            data={parksTrailsAndBeaches}
            id="parks_trails_and_beaches"
            title="Parks, Trails and Beaches"
            type="distance"
          />
          <TableElement
            data={westCoastActivities}
            id="west_coast_activities"
            title="West Coast Activities"
            type="distance"
          />
          <TableElement data={sportFishing} id="sport_fishing" title="Sport Fishing" type="fishing" />
          <TableElement data={fineDining} id="fine_dining" title="Fine Dining" type="restaurant" />
          <TableElement data={westCoastArt} id="west_coast_art" title="West Coast Art" type="distance" />
          <TableElement data={churches} id="churches" title="Churches" type="distance" />
          <TableElement data={moreLinks} id="more_links" title="More Links of Interest" type="links" />
        </Tables>
      </Grid>
    </div>
  );
};

export default styled(TodoTogo)`
  position: relative;
  padding: 40px 0;
  max-width: 1130px;
  margin: 0 auto;

  ${media(Breakpoint.Tablet)} {
    padding: 100px 0;
  }

  ${TableElement} {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }

    ${media(Breakpoint.Tablet)} {
      margin-bottom: 60px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

import styled from "@emotion/styled";
import React from "react";

import { media, Breakpoint, themeColor } from "../../theme";

const Icon = styled.img`
  display: block;
  margin: 0 auto 20px;

  ${media(Breakpoint.Tablet)} {
    margin: 0 auto 40px;
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${themeColor("white")};
  margin: 0;

  span {
    display: none;

    ${media(Breakpoint.Tablet)} {
      display: inline;
    }
  }
`;

interface SeasonsSeparatorProps {
  className?: string;
  text: string;
}

const SeasonsSeparator: React.FC<SeasonsSeparatorProps> = ({ className, text }) => {
  return (
    <div className={className}>
      <Icon alt="logo" src={`/seasons.svg`} width={100} height={52} />
      <Title dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default styled(SeasonsSeparator)`
  position: relative;
  background: ${themeColor("grey700")};
  padding: 40px 20px;

  ${media(Breakpoint.Tablet)} {
    padding: 100px 0;
  }
`;
